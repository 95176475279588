<template>
  <div class="dictionary-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-input
            v-model="searchModel.dictionaryKey"
            placeholder="键名"
            type="text"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button type="primary" @click="handleAddClick">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <my-el-table
      :data="dictionaryList"
      :total="total"
      :page-index="pageIndex"
      :page-size="pageSize"
      @size-change="handlePageSizeChange"
      @current-change="handlePageIndexChange"
      >
      <el-table-column prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="dictionaryKey" header-align="center" align="center" label="键名"></el-table-column>
      <el-table-column prop="dictionaryValue" header-align="center" align="center" label="键值"></el-table-column>
      <el-table-column prop="sortId" width="50" header-align="center" align="center" label="排序"></el-table-column>
      <el-table-column header-align="center" align="center" width="90" label="操作">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <el-tooltip class="item" effect="light" content="编辑" :open-delay="500" placement="top">
            <i @click="handleEditClick(scope.row)" class="text-info far fa-edit mr-2 operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </my-el-table>

    <my-dialog ref="formDialog"
      :title="formDialogTitle"
      size="md"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleOkClick">
      <dictionary-form
        ref="dictionaryForm"
        :dictionaryModel="dictionaryModel">
      </dictionary-form>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import MyElTable from '@/components/MyElTable/MyElTable'
import DictionaryForm from '@/views/DictionaryManage/DictionaryForm'
import DictionaryModel from '@/model/DictionaryModel'
import dictionaryService from '@/services/dictionaryService'
import PageInfo from '@/support/PageInfo'
import PageOperation from '@/mixins/PageOperation'

export default {
  name: 'DictionaryIndex',
  mixins: [
    PageOperation
  ],
  components: {
    MyDialog,
    MyElTable,
    MySearchBar,
    DictionaryForm
  },
  data () {
    return {
      total: 0,
      dictionaryList: [],
      dictionaryModel: new DictionaryModel(),
      formDialogTitle: '',
      searchModel: {
        dictionaryKey: ''
      }
    }
  },
  methods: {
    handleSearchClick () {
      this._getDictionaryList()
    },
    handleAddClick () {
      this.formDialogTitle = '添加字典信息'
      this.dictionaryModel = new DictionaryModel()
      this.$refs.formDialog.open()
    },
    handleEditClick (row) {
      this.formDialogTitle = '编辑字典信息'
      this.dictionaryModel = new DictionaryModel(row)
      this.$refs.formDialog.open()
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _dictionaryModel = new DictionaryModel(row)
          dictionaryService.delete(_dictionaryModel.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getDictionaryList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.dictionaryForm.validate()(valid => {
        if (valid) {
          if (!this.dictionaryModel.resourceId) {
            this._save()
          } else {
            this._edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    _getDictionaryList () {
      dictionaryService.list(new PageInfo({ pageSize: this.pageSize, pageIndex: this.pageIndex, fieldOrder: 'dictionaries.dictionaryKey asc, dictionaries.sortId asc' }), this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.total = res.data.pageCount
            this.dictionaryList = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err.request.response)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _save () {
      dictionaryService.add(this.dictionaryModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getDictionaryList()
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _edit () {
      dictionaryService.edit(this.dictionaryModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getDictionaryList()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getDictionaryList()
    this.refreshMethod = this._getDictionaryList
  }
}
</script>
