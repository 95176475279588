import BaseService from '@/services/BaseService'

let resourceName = 'dictionary'

class DictionaryService extends BaseService {
}

const dictionaryService = new DictionaryService(resourceName)

export default dictionaryService
