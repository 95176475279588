import utility from '@/common/utility'

export default class DictionaryModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.dictionaryGuid
      this.dictionaryGuid = props.dictionaryGuid
      this.dictionaryKey = props.dictionaryKey
      this.dictionaryValue = props.dictionaryValue
      this.sortId = props.sortId
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.dictionaryGuid) this.dictionaryGuid = ''
    if (!this.dictionaryKey) this.dictionaryKey = ''
    if (!this.dictionaryValue) this.dictionaryValue = ''
    if (!this.sortId) this.sortId = 0
  }

  generatePrimaryKey () {
    this.dictionaryGuid = utility.getUuid()
    this.resourceId = this.dictionaryGuid
  }
}
