<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="dictionaryModel"
    label-width="100px"
    >
    <el-form-item label="键名：" prop="dictionaryKey">
      <el-input
        type="text"
        v-model="dictionaryModel.dictionaryKey">
      </el-input>
    </el-form-item>
    <el-form-item label="键值：" prop="dictionaryValue">
      <el-input
        type="text"
        v-model="dictionaryModel.dictionaryValue">
      </el-input>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="dictionaryModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import DictionaryModel from '@/model/DictionaryModel'

export default {
  name: 'DictionaryForm',
  props: {
    dictionaryModel: {
      type: DictionaryModel,
      default () {
        return new DictionaryModel()
      }
    }
  },
  data () {
    return {
      rules: {
        dictionaryKey: [
          {
            required: true,
            message: '请输入键名',
            trigger: 'blur'
          }
        ],
        dictionaryValue: [
          {
            required: true,
            message: '请输入键值',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    }
  }
}
</script>
